@import "~bootstrap/scss/bootstrap.scss";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import '~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
@import "~animate.css";

$green: #80c641;

@font-face {
  font-family: 'OpenSansBold';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansBoldItalic';
  src: url('../fonts/OpenSans-BoldItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansBoldExtraBold';
  src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansBoldExtraBoldItalic';
  src: url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansItalic';
  src: url('../fonts/OpenSans-Italic.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../fonts/OpenSans-Light.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansLightItalic';
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansSemiBoldItalic';
  src: url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype')
}
@font-face {
  font-family: 'Tangerine';
  src: url('../fonts/Tangerine-Regular.ttf') format('truetype')
}
html {
  scroll-behavior: smooth;
  font-size:100%;
}
body{
  font-family: OpenSansLight, Helvetica, Arial, serif;

}
h1{
  font-size: 3rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.1rem;
  line-height: 2rem;
}
h4{
  font-size: 1rem;
}
h5{
  font-size: 0.9rem;
}
h6{
  font-size: 0.83rem;
}