@import "./../base";
@import "./base";
section > div{
  background-color:white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  min-width: 380px;
  box-shadow: 0 0 20px 15px rgba(0,0,0,0.1);
}
img{
  height:75px;
}