@import "./../base";
html{
  height: 100%;
  background-image: radial-gradient(circle farthest-corner at 75% 95%, #fff, #C0CBD6);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-attachment: fixed;
}
body{
  background: transparent;
  height: 100%;
}

form {
  label{
    font-size: 12px;
  }
  button, .button{
    background: $green !important;
    color: white !important;
    > .spinner-border{
      display:none;
      position: relative;
      top: -4px;
      &.show{
        display: inline-block !important;
      }
    }
  }
}

